import React from "react";
import styled from "styled-components";
import Participant from "../Participant";
import ParticipantInfo from "../ParticipantInfo";
import useVideoContext from "../../hooks/useVideoContext";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  width: 99%;
  align-self: center;
`;

const GridItem = styled.div`
  margin: auto;
  width: calc(${({ count }) => 100 / count}% - 13px);
`;

const AspectRatio = styled.div`
  padding-top: 140%;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 56%;
  }
`;

const ParticipantWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.dark};

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const GridView = ({ isTherapist }) => {
  const { participants } = useVideoContext();

  return (
    <Wrapper>
      {participants.map(participant => (
        <GridItem count={participants.length} key={participant.sid}>
          <AspectRatio>
            <ParticipantWrap>
              <Participant
                participant={participant}
                showAdminControls={isTherapist}
                priority="standard"
              >
                <ParticipantInfo participant={participant} />
              </Participant>
            </ParticipantWrap>
          </AspectRatio>
        </GridItem>
      ))}
    </Wrapper>
  );
};

export default GridView;
