import React from "react";
import styled from "styled-components";
import ParticipantTracks from "../ParticipantTracks";
import InitialsBadge from "./InitialsBadge";
import Reconnecting from "./Reconnecting";
import Controls from "./Controls";
import usePublications from "../../hooks/usePublications";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import useVideoContext from "../../hooks/useVideoContext";
import useTrack from "../../hooks/useTrack";
import useInactive from "../../hooks/useInactive";
import useHoverOrTouch from "../../hooks/useHoverOrTouch";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import { datadogEvent } from "../../datadog";

const ParticipantWrap = styled.div`
  height: 100%;
  overflow: hidden;
`;

export default function Participant({
  participant,
  disableAudio,
  disableVideo,
  enableScreenShare,
  isScreenshare,
  showAdminControls,
  setVideo,
  full,
  priority = "low",
  children,
  isMain = false,
}) {
  const isInactive = useInactive(3000);
  const [hoverRef, isHovering] = useHoverOrTouch();
  const {
    room: { localParticipant },
    selectedParticipant,
    setSelectedParticipant,
    screenShareParticipant,
    sendMessage,
  } = useVideoContext();
  const { displayName } = useParticipantInfo(participant.identity);
  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.kind === "video");
  const isVideoEnabled = Boolean(videoPublication);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const pinParticipant = () => {
    if (selectedParticipant) {
      datadogEvent({
        category: "session",
        event: "participant_unpinned",
        component: "Participant",
        metadata: {
          participant:
            selectedParticipant === localParticipant
              ? "self"
              : selectedParticipant.identity,
        },
      });
    }

    setSelectedParticipant(participant);

    if (participant !== selectedParticipant) {
      datadogEvent({
        category: "session",
        event: "participant_pinned",
        component: "Participant",
        metadata: {
          participant:
            participant === localParticipant ? "self" : participant.identity,
        },
      });
    }
  };

  const toggleMuteParticipant = () => {
    const message = isAudioEnabled ? "mute" : "unmute";

    sendMessage({
      type: message,
      to: participant.identity,
    });

    datadogEvent({
      category: "session",
      event: isAudioEnabled ? "participant_muted" : "participant_unmuted",
      component: "Participant",
      metadata: {
        participant: participant.identity,
      },
    });
  };

  const removeParticipant = () => {
    sendMessage({
      type: "kick",
      to: participant.identity,
    });

    datadogEvent({
      category: "session",
      event: "participant_removed",
      component: "Participant",
      metadata: {
        participant: participant.identity,
      },
    });
  };

  const isReconnecting = participant.state === "reconnecting";

  // If video is disabled, we need to keep audio tracks around, but leave no visible trace
  if (disableVideo) {
    return (
      <ParticipantTracks
        isMain={isMain}
        participant={participant}
        disableAudio={disableAudio}
        enableScreenShare={enableScreenShare}
        videoPriority="low"
        setVideo={setVideo}
        disableVideo={true}
      />
    );
  }

  return (
    <ParticipantWrap id={`participant-${participant.identity}`} ref={hoverRef}>
      {isReconnecting ? (
        <Reconnecting />
      ) : (
        <>
          <ParticipantTracks
            isMain={isMain}
            participant={participant}
            disableAudio={disableAudio}
            enableScreenShare={enableScreenShare}
            videoPriority={priority}
            setVideo={setVideo}
          />
          {!isVideoEnabled && !isScreenshare && (
            <InitialsBadge name={displayName} />
          )}
        </>
      )}
      {children}
      <Controls
        isVisible={full ? !isInactive : isHovering}
        showAdminControls={showAdminControls}
        isSelected={selectedParticipant === participant}
        setSelected={pinParticipant}
        isAudioEnabled={isAudioEnabled}
        toggleMuteParticipant={toggleMuteParticipant}
        showPinControls={
          !screenShareParticipant || screenShareParticipant === localParticipant
        }
        removeParticipant={removeParticipant}
        identity={participant.identity}
        displayName={displayName}
      />
    </ParticipantWrap>
  );
}
