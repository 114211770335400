import { isMobile } from "@sussex/react-kit/utils";

// See: https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#concepts-and-terminology
// for recommended resolutions.
const resolutions = [
  {
    labelKey: "sessioncontrols.resolutions.high",
    dimensions: { width: 1280, height: 720 },
  },
  {
    labelKey: "sessioncontrols.resolutions.medium",
    dimensions: { width: 960, height: 540 },
  },
  {
    labelKey: "sessioncontrols.resolutions.low",
    dimensions: { width: 640, height: 480 },
  },
];

const desktopVideoConfig = {
  ...resolutions[0].dimensions,
  frameRate: 24,
};

const mobileVideoConfig = {
  ...resolutions[2].dimensions,
  facingMode: "user",
  frameRate: 24,
};

// https://media.twiliocdn.com/sdk/js/video/releases/2.19.0/docs/global.html#ConnectOptions
// https://www.twilio.com/docs/video/tutorials/developing-high-quality-video-applications#grid-mode
const getConnectOptions = (roomType = "peer-to-peer") => {
  const options = {
    maxAudioBitrate: 16000,
    networkQuality: { local: 1, remote: 1 },
    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. Simulcast should be disabled if
    // you are using Peer-to-Peer or 'Go' Rooms.
    preferredVideoCodecs: [
      {
        codec: "VP8",
        simulcast: roomType !== "peer-to-peer" && roomType !== "go",
      },
    ],
  };

  if (isMobile) {
    options.bandwidthProfile = {
      video: {
        maxSubscriptionBitrate: 2500000,
      },
    };
  }
};

export {
  getConnectOptions,
  desktopVideoConfig,
  mobileVideoConfig,
  resolutions,
};
