import { useCallback, useState, useRef, useEffect } from "react";
import EventEmitter from "events";
import Video from "twilio-video";
import { isMobile } from "@sussex/react-kit/utils";
import { getConnectOptions } from "../../../connectionOptions";
import {
  useAppStateContext,
  actions,
} from "../../../providers/AppStateProvider";

const { SESSION_END } = actions;

window.TwilioVideo = Video;

export default function useRoom(localTracks, onError) {
  const { dispatch, state } = useAppStateContext();
  const [room, setRoom] = useState(new EventEmitter());
  const [isConnecting, setIsConnecting] = useState(false);
  const options = getConnectOptions(state.session.roomType);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const connect = useCallback(
    token => {
      setIsConnecting(true);

      return Video.connect(token, { ...options, tracks: localTracks })
        .then(newRoom => {
          setRoom(newRoom);

          const disconnect = () => {
            dispatch({ type: SESSION_END });
            newRoom.disconnect();
          };

          newRoom.once("disconnected", () => {
            // Reset the room only after all other `disconnected` listeners
            // have been called.
            setTimeout(() => isMounted.current && setRoom(new EventEmitter()));
            window.removeEventListener("beforeunload", disconnect);
            if (isMobile) {
              window.removeEventListener("pagehide", disconnect);
            }
          });

          newRoom.localParticipant.videoTracks.forEach(publication => {
            // The subscribers will set the track to the appropriate priority
            // based on the type of view being used.
            publication.setPriority("low");
          });

          newRoom.localParticipant.videoTracks.forEach(publication => {
            // The subscribers will set the track
            // to the appropriate priority based on the type of view
            // being used.
            publication.setPriority("low");
          });

          window.twilioRoom = newRoom;

          // Add a listener to disconnect from the room when a user closes
          // their browser
          window.addEventListener("beforeunload", disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user
            // closes their browser
            window.addEventListener("pagehide", disconnect);
          }
        })
        .catch(onError)
        .then(() => setIsConnecting(false));
    },
    [options, onError, localTracks, dispatch],
  );

  return { room, isConnecting, connect };
}
